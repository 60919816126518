@import "../../_globalColor";

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.deve-roundedimg {
  object-fit: cover;
  margin-right: 2rem;
  width: 14rem;
  height: 14rem;
  box-shadow: 0 0.5rem 3rem $lightBoxShadowDarker;
  border-radius: 50%;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: darkgray;
  transition: all 0.3s;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}

.deve-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 8rem;
  margin-top: 5rem;
}

.deve2-image-div {
  display: none;
}
/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }

  .deve-image-div {
    display: none;
  }
  .deve2-image-div{
    display: flex;
    max-width: 100%;
    height: auto;
    margin-left: 2rem;
    margin-top: 2rem;
    justify-content: center;
  }
}
